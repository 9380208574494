const GlobalDialogStore = {
  namespaced: true,
  state: () => ({
    show: false,
    title: '',
    message: '',
    type: '',
    email: ''
  }),
  mutations: {
    show(state, props) {
      state.title = props.title;
      state.message = props.message;
      state.type = props.type;
      state.email = props.email;
      state.show = true;
    },
    hide(state) {
      state.show = false;
      state.title = '';
      state.message = '';
      state.type = '';
      state.email = '';
    }
  },
  actions: {}
};

export default GlobalDialogStore;
