<template>
  <v-dialog v-model="showDialog" max-width="400" no-click-animation persistent>
    <v-card
      id="card"
      elevation="0"
      class="d-flex flex-column py-3 align-center"
    >
      <v-card-title class="mb-3">{{ title }}</v-card-title>
      <v-card-text class="text-center">
        <v-icon v-if="type === 'success'" x-large color="green"
          >mdi-check-outline</v-icon
        >
        <v-icon v-else-if="type === 'error'" x-large color="red"
          >mdi-alert-octagon-outline</v-icon
        >
        <p class="mt-4 mb-0" v-if="message !== ''">{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="130"
          @click="sendValidationEmail()"
          :loading="isLoading"
          v-if="type === 'validation'"
          color="warning"
          >Send email</v-btn
        >
        <v-btn width="200" @click="close()" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import config from "../config";
export default {
  name: "SniprshotDialog",
  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    close: function () {
      this.$store.commit("globalDialog/hide");
    },
    sendValidationEmail: function () {
      this.isLoading = true;
      fetch(`${config.URL}/users/sendemail`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.$store.state.globalDialog.email,
        }),
      }).then((res) => {
        this.$store.commit("globalDialog/hide");
        this.$toasted.show("Validation Email Sent!", {
          theme: "toasted-primary",
          type: "info",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "login",
        });
      });
    },
  },
  computed: {
    showDialog: function () {
      return this.$store.state.globalDialog.show;
    },
    message: function () {
      return this.$store.state.globalDialog.message;
    },
    title: function () {
      return this.$store.state.globalDialog.title;
    },
    type: function () {
      return this.$store.state.globalDialog.type;
    },
  },
  watch: {
    showDialog: function (val) {
      if (!val) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
