import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import fetchWrapper from './plugins/custom/fetchWrapper';
import toasted from 'vue-toasted';
import linkify from 'vue-linkify'
import VueCodeHighlight from 'vue-code-highlight';
import '@mdi/font/css/materialdesignicons.css'; // Ensure this is added

Vue.config.productionTip = false;

// fetchWrapper.config(router);

Vue.prototype.$fetch = fetchWrapper.fetch;

Vue.use(require('vue-cookies'));
Vue.use(toasted);
Vue.use(VueCodeHighlight);
Vue.directive('linkified', linkify);
export default new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');