import Vue from 'vue';
import Vuex from 'vuex';
import GlobalDialogStore from './modules/GlobalDialog';

Vue.use(Vuex);

const userInitialState = {
  id: '',
  email: '',
  accessToken: '',
  refreshToken: '',
  premium: false,
  size: 0,
  twofactor: false,
  captureSize: 0,
  userLevel: 0,
  userPermissions: []
};

export default new Vuex.Store({
  state: {
    user: userInitialState,
    connected: false,
    search: "",
    captures: [],
    view: 0
  },
  mutations: {
    connectUser(state, user) {
      if(user.userLevel == null){
        user.userLevel = 0;
      }
      if(user.userPermissions == null){
        user.userPermissions = [];
      }
      state.user = user;
      localStorage.setItem('access_token', user.accessToken);
      Vue.$cookies.set('refresh_token', user.refreshToken);
      state.connected = true;

    },
    disconnectUser(state) {
      state.user = userInitialState;
      localStorage.removeItem('access_token');
      state.connected = false;
    },
    refreshAccessToken(state, accessToken) {
      localStorage.setItem('access_token', accessToken);
      state.user.accessToken = accessToken;
      state.user.size = accessToken.size;
    }
  },
  actions: {},
  modules: {
    globalDialog: GlobalDialogStore
  }
});
