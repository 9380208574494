<template>
  <div align="center">
    <v-toolbar width="1200" class="mb-15" color="transparent" elevation="0" flat fixed>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img alt="Onyshare Logo" class="shrink" contain src="../../public/logo.png" transition="scale-transition"
            width="40" />
        </router-link>
        <v-toolbar-title class="app-title">Onyshare</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-up"> </v-toolbar-items>

      <div class="hidden-sm-and-down my-0">
        <router-link to="/features" class="mx-2" style="text-decoration: none">Features</router-link>
        <router-link to="/features" class="mx-2" style="text-decoration: none">Pricing</router-link>
        <router-link to="/download" class="mx-2" style="text-decoration: none">Download</router-link>
      </div>
      <v-btn v-if="!$store.state.connected" @click="openDialog('login'), accountNotVerified = false" class="mx-1" color="primary" outlined>
        Log in
      </v-btn>
      <v-btn v-if="!$store.state.connected" @click="openDialog('register'), accountNotVerified = false" class="mx-1" color="primary" dark>
        SIGN UP
      </v-btn>

      <v-btn v-if="$store.state.connected" @click="logout" class="mx-1" color="primary" outlined>
        Log out
      </v-btn>
      <v-btn v-if="$store.state.connected" @click="$router.push('/dashboard')" class="mx-1" color="primary" dark>
        Dashboard
      </v-btn>

      <v-dialog v-model="dialog" width="400" no-click-animation :persistent="loading">
        <!-- Create account -->
        <v-card v-if="dialogType === 'register'" id="card" elevation="0" class="py-3" min-height="270">
          <v-card-text class="regtitle text-center"></v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <v-text-field outlined dense class="px-10" name="email" type="email" autocomplete="username" label="Email Address" v-model="email"
              :rules="[rules.required, rules.validEmail]"></v-text-field>
            <div class="d-flex">
              <v-text-field outlined dense full-width class="pl-10 pr-1" name="password" label="Password"
                type="password" autocomplete="new-password" v-model="password" :rules="[rules.required]"></v-text-field>
              <v-text-field outlined dense full-width class="pr-10 pl-1" name="password2"
                label="Confirm Password" type="password" autocomplete="new-password" v-model="password2"
                :rules="[rules.requiredPassword, rules.required, rules.length]"></v-text-field>
            </div>
            <v-card-actions class="flex-column">
              <span style="color: red; margin-bottom: 3px" v-if="errorMessage !== ''"><small>{{ errorMessage
              }}</small></span>
              <v-btn @click="register" :loading="loading" :disabled="!isFormValid" width="250" large color="primary"
                type="submit">
                Create Account</v-btn>
              <a class="mt-2" @click="alreadyReg()"><small>Already got an account?</small></a>
              <small class="mt-2">By signing up, you agree to our <a @click="regTOS()">Terms of Service.</a></small>
            </v-card-actions>
          </v-form>
        </v-card>

        <!-- Login -->
        <v-card v-else-if="dialogType === 'login'" id="card" elevation="0" class="py-3" width="400" min-height="250">
          <v-card-text class="regtitle text-center"></v-card-text>
          <v-form ref="form" v-model="isFormValid" autocomplete="true">
            <v-text-field v-if="!accountNotVerified" outlined dense class="px-10" name="email" label="Email Address" v-model="email"
              :rules="[rules.required, rules.validEmail]" placeholder="Email Address"></v-text-field>
              <v-text-field v-if="accountNotVerified" readonly outlined dense class="px-10" name="email" label="Email Address" v-model="email" type="email" autocomplete="username"
              :rules="[rules.required, rules.validEmail]"></v-text-field>
            <v-text-field v-if="!accountNotVerified" outlined dense class="px-10" name="password" label="Password" type="password" autocomplete="current-password"
              v-model="password" :rules="[rules.required]"></v-text-field>
            <v-card-actions class="flex-column">
              <span v-if="errorMessage != ''" style="color: red"><small>{{ errorMessage }}</small></span>
              <v-btn v-if="!accountNotVerified" @click="login" :loading="loading" :disabled="!isFormValid" large color="primary" width="250"
                type="submit">Login
              </v-btn>
              <v-btn v-if="accountNotVerified" dark @click="sendValidationEmail" :loading="loading" large color="green"
                width="250" type="submit" class="ma-0 pa-0 mt-1">Send Verify Email
              </v-btn>
              <a class="mt-2" @click="forgottenPassword()"><small>Forgot password?</small></a>
              <small class="mt-2">Don't have an account?<a @click="toRegDialog()"> Create one for free</a>.</small>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- 2fa -->
      <v-dialog max-width="400" v-model="twofa" no-click-animation persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Two Factor Authentication</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                Enter your two-factor sign in code found in your email.
              </div>
              <v-otp-input class="mt-4" length="6" :disabled="tfaloading" @finish="onFinish" v-model="otp">
              </v-otp-input>
              <div class="pt-0 mt-0 pb-0 text--primary">
                <small>You can request a new two-factor sign in code by logging in
                  again.</small>
              </div>
              <v-overlay absolute :value="tfaloading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-overlay>
            </v-card-text>
            <v-card-actions class="justify-end pt-0">
              <v-btn text color="primary" @click="twofa = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- success enable 2fa -->
      <v-dialog max-width="400" v-model="twofactorerr">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Something went wrong</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                You have either entered the wrong two-factor sign in code or the
                token is invalid. Please request a new one by logging in again.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="twofactorerr = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-toolbar>
  </div>
</template>

<style scoped>
.v-text-field>>>label {
  font-size: 12px;
}

.v-text-field>>>input {
  font-size: 14px;
}

.regtitle {
  font-size: 16px;
  font-weight: bold;
}

.app-title {
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #1976d2;
}
</style>

<script>
import config from "../config";
import validation from "email-validator";

export default {
  name: "Navbar",

  data: function () {
    return {
      dialog: false,
      dialogType: "",
      rules: {
        length: (value) => value.length >= 8 || "Must be 8 characters or more",
        validEmail: (value) =>
          validation.validate(value) == true ||
          "Please enter a valid email address.",
        required: (value) => !!value || "Required.",
        requiredPassword: (value) =>
          value == this.password || "Password must match",
      },
      isFormValid: false,
      password: "",
      password2: "",
      email: "",
      loading: false,
      errorMessage: "",
      resendBtn: false,
      twofa: false,
      otp: "",
      tfaloading: false,
      twofactorerr: false,
      accountNotVerified: false,
    };
  },
  methods: {
    sendValidationEmail() {
      fetch(`${config.URL}/users/sendemail`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.email,
        }),
      }).then((res) => {
        this.$store.commit("globalDialog/hide");
        this.$toasted.show("Validation Email Sent!", {
          theme: "toasted-primary",
          type: "info",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "login",
        });
      });
      this.dialog = false;
      this.email = "";
      this.twofa = false;
      this.tfaloading = false;
      this.password = "";
      this.otp = "";
      this.loading = false;
      this.twofactorerr = false;
      this.accountNotVerified = false;
      this.$refs.form.reset();
    },
    onFinish() {
      this.tfaloading = true;
      fetch(`${config.URL}/users/2falogin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.email, code: this.otp }),
      })
        .then((res) => {
          if (res.status !== 200) {
            this.twofa = false;
            this.password = "";
            this.email = "";
            this.otp = "";
            this.loading = false;
            this.tfaloading = false;
            this.twofactorerr = true;
            this.accountNotVerified = false;
            return;
          }
          res.json().then((data) => {
            if (res.status !== 200) {
              this.twofa = false;
              this.password = "";
              this.otp = "";
              this.loading = false;
              this.tfaloading = false;
              this.twofactorerr = true;
              this.accountNotVerified = false;
            } else {
              this.twofa = false;
              this.otp = "";
              this.email = "";
              this.tfaloading = false;
              this.loading = false;
              this.dialog = false;
              this.twofactorerr = false;
              this.accountNotVerified = false;

              this.$store.commit("connectUser", {
                id: data.id,
                email: data.email,
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                premium: data.premium,
                size: data.size,
                twofactor: data.twofactor,
                userLevel: data.userLevel,
                userPermissions: data.userPermissions
              });

              this.$router.push("/dashboard/captures");

              this.$toasted.show("Successfully logged in", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "login",
              });
            }
          });
        })
        .catch((err) => {
          this.email = "";
          this.twofa = false;
          this.tfaloading = false;
          this.password = "";
          this.otp = "";
          this.loading = false;
          this.twofactorerr = true;
          this.accountNotVerified = false;
        });
    },
    regTOS() {
      this.dialog = false;
      this.$router.push("/terms");
    },
    toRegDialog() {
      this.openDialog("register");
    },
    alreadyReg() {
      this.openDialog("login");
    },
    forgottenPassword() {
      this.dialog = false;
      this.$router.push("/forgot");
    },
    register(e) {
      e.preventDefault();
      this.loading = true;
      fetch(`${config.URL}/users/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: this.password,
          email: this.email,
        }),
      })
        .then((res) => {
          console.log(res.status);
          res.json().then((data) => {
            console.log(res.status);
            if (res.status !== 200) {
              if (data.resend) {
                this.$store.commit("globalDialog/show", {
                  title: "Account is not verified",
                  message:
                    "This email address is already registered but has not been verified. If you have lost the verification email, you can request a new one by clicking the button below.",
                  type: "validation",
                  email: this.email,
                });
              } else {
                this.$store.commit("globalDialog/show", {
                  title: "Something went wrong",
                  message: data.message,
                  type: "error",
                  email: this.email,
                });
              }
              this.dialog = false;
              this.errorMessage = data.message;
              this.password = "";
              this.password2 = "";
              this.loading = false;
              this.accountNotVerified = false;
            } else {
              this.$store.commit("globalDialog/show", {
                title: "Account successfully created",
                message:
                  "Please verify your account by clicking the confirmation link that has been sent to your email.",
                type: "success",
              });
              //this.errorMessage = data.message;
              this.dialog = false;
              this.password = "";
              this.password2 = "";
              this.loading = false;
            }
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.password = "";
          this.password2 = "";
          this.registerLoading = false;
          this.accountNotVerified = false;
          console.log(err);
        });
    },
    login(e) {
      e.preventDefault();
      this.loading = true;
      this.twofactorerr = false;
      fetch(`${config.URL}/users/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.email, password: this.password }),
      }).then((res) =>
        res.json().then((data) => {
          if (res.status !== 200 && res.status !== 401) {
            this.errorMessage = data.message;
            this.password = "";
            this.loading = false;
            this.accountNotVerified = false;
            this.$refs.form.reset();
          } else if (res.status === 401) {
            this.errorMessage = "Account not verified. Send verify email again?";
            this.password = "";
            this.loading = false;
            this.accountNotVerified = true;
          }
          else {
            if (data.twofactor) {
              this.loading = false;
              this.dialog = false;
              this.twofa = true;
              this.accountNotVerified = false;
            } else {
              // this.$store.commit("globalDialog/show", {
              //   title: data.message,
              //   type: "success",
              // });
              this.loading = false;
              this.$refs.form.reset();
              this.dialog = false;
              this.accountNotVerified = false;
              this.$store.commit("connectUser", {
                id: data.id,
                email: data.email,
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                premium: data.premium,
                size: data.size,
                twofactor: data.twofactor,
                userLevel: data.userLevel,
                userPermissions: data.userPermissions
              });

              this.$router.push("/dashboard/captures");

              this.$toasted.show("Successfully logged in", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "login",
              });
            }
          }
        })
      );
    },
    logout() {
      this.$store.commit("disconnectUser");
      this.$toasted.show("Successfully logged out", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "logout",
      });
    },
    openDialog(type) {
      this.dialogType = type;
      this.dialog = true;
    },
  },
  watch: {
    dialog: function (val) {
      if (val === false) {
        this.dialogType = "";
        this.errorMessage = "";
        //this.$refs.form.reset();
      }
    },
  },
};
</script>
